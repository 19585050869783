import React from 'react';
import msg from './messages.js';
import { injectIntl } from "react-intl";
import styled from 'styled-jss';

import {
  ScrollMenu
} from 'react-horizontal-scrolling-menu';

import 'react-horizontal-scrolling-menu/dist/styles.css';

class MenuHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      selectedCategory: null,
    };
  }

  handleLocalCategoryClick = (category) => {
    this.setState({ selectedCategory: category });
    this.props.handleCategoryClick(category);
  };

  handleTagClick = (tagId) => {
    this.setState((prevState) => {
      const selectedTags = prevState.selectedTags.includes(tagId)
        ? prevState.selectedTags.filter((id) => id !== tagId)
        : [...prevState.selectedTags, tagId];
      return { selectedTags };
    });
    this.props.handleTagClick(tagId);
  };

  render() {
    const { dishes, defaultLang, currentLang, subTitleOpenHours, intl, tagsToShow } = this.props;
    const { selectedTags, selectedCategory } = this.state;
    const translate = intl.formatMessage;
    const filterTags = [
      { id: 'veggie', label: translate(msg.vegetarianTag) },
      { id: 'vegan', label: translate(msg.veganTag) },
      { id: 'gluten_free', label: translate(msg.glutenFreeTag) },
    ].filter(tag => tagsToShow.includes(tag.id));

    return (
        <header className="menu-header" id="menu-header">

        <div className="menu-header__container">
            <NoScrollbar>
              <ScrollMenu>
                {dishes.map((category) => (
                  <CategoryButton
                    name='category_button'
                    key={category.category[defaultLang]}
                    onClick={() =>
                      this.handleLocalCategoryClick(category.category[defaultLang])
                    }
                    selected={selectedCategory === category.category[defaultLang]}
                  >
                    {category.category[currentLang] ||
                      category.category[defaultLang]}
                  </CategoryButton>
                ))}
              </ScrollMenu>
            </NoScrollbar>

          <div className="menu-header__tag-section">
            {filterTags.map((tag) => (
              <TagButton
                name='tag_button'
                key={tag.id}
                onClick={() => this.handleTagClick(tag.id)}
                selected={selectedTags.includes(tag.id)}
              >
                {tag.label}
              </TagButton>
            ))}
          </div>
        </div>
        <h4
          type="secondary"
          style={{
            margin: '1rem 1rem 0 1rem',
            fontWeight: 'bold',
          }}
        >
          {' '}
          {subTitleOpenHours}
        </h4>
      </header>
    );
  }
}

// Horizontal Scrolling Menu Components and Utilities
const NoScrollbar = styled('div')({
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    '& .react-horizontal-scrolling-menu--scroll-container': {
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
    },
  });
  
const CategoryButton = styled('button')({
  padding: '8px 16px',
  borderRadius: '8px',
  border: (props) =>
    props.selected ? '1px solid #2563eb' : '1px solid #e5e7eb',
  backgroundColor: (props) => (props.selected ? '#f0f7ff' : '#f9fafb'),
  color: (props) => (props.selected ? '#2563eb' : '#374151'),
  cursor: 'pointer',
  display: 'flex',
  marginRight: '8px',
  alignItems: 'center',
  gap: '4px',
  transition: 'all 0.2s ease',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#f0f7ff',
    color: '#2563eb',
    borderColor: '#2563eb',
  },
});

const TagButton = styled('button')({
    padding: '6px 12px',
    borderRadius: '20px',
    marginRight: '8px',
    border: (props) =>
      props.selected ? '2px solid #059669' : '1px solid #e5e7eb',
    backgroundColor: (props) => (props.selected ? '#ecfdf5' : '#f9fafb'),
    color: (props) => (props.selected ? '#059669' : '#4b5563'),
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#f0fdf4',
      color: '#059669',
      borderColor: props => props.selected ? '2px solid #059669' : '1px solid #059669',
    },
  });
export default injectIntl(MenuHeader);





