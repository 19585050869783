import React from 'react';
import { Layout, Card, Button, List, Typography, Divider, Badge, Spin, Alert, Icon } from 'antd';
import { ZoomableImage } from '../../../components/ZoomableImage'
import { ShoppingCartOutlined, PlusSquareOutlined, MinusOutlined, PlusOutlined, DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { injectIntl } from "react-intl";
import { Route, withRouter } from 'react-router-dom';
import { SmartRequests } from '../../../utilities';
import { isEqual } from 'lodash';
import TransitionSwitch from '../../../components/TransitionSwitch.js'
import MenuHeader from './MenuHeader.js'
import moment from 'moment-timezone';

import msg from './messages.js';
import ConfirmOrderModal from './ConfirmOrderModal';
import ModifyModal from './ModifyModal';
import OrderChangedModal from './OrderChangedModal.js';
import MyOrders from './MyOrders.js'

const { Content, Sider, Footer } = Layout;
const { Title, Text, Paragraph } = Typography;


const MenuComponent = ({ dishes, categoryRefs, addToOrder, modifyModal, order, isMobile, defaultLang, currentLang }) => {
  return (
    <>
      <style> {`.ant-badge {width: 100%;}`} </style>
      {dishes.map((categoryDish, indexCategory) => (
        <React.Fragment key={categoryDish.category[defaultLang]}>
          <Title name='category_name' level={4} style={isMobile ? { textAlign: 'center' } : {}}><span ref={categoryRefs[categoryDish.category[defaultLang]]} style={{ scrollMarginTop: 75 }}>{categoryDish.category[currentLang] || categoryDish.category[defaultLang]}</span></Title>
          <List className='food-list-center-sm'
            grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3 }}
            style={isMobile ? { maxWidth: 400, margin: 'auto' } : {}}
            dataSource={categoryDish.category_plates}
            renderItem={(dish, indexPlate) => (
              <List.Item
              style={{paddingBottom: 
                isMobile && 
                order.length > 0 && 
                indexPlate === categoryDish.category_plates.length - 1 &&
                indexCategory === dishes.length - 1
                  ? '45px' 
                  : undefined}}
                onClick={() => {
                  if ((dish.extras != null && dish.extras.length > 0) || (dish.option_groups != null && dish.option_groups.length > 0) || (dish.description != null && dish.description[defaultLang] != '')) {
                    modifyModal(-1, true, { ...dish, selectedCondiments: {}, selectedExtras: [], category: categoryDish.category, quantity: 1 })
                  }
                }}
              >
                <Badge count={order.filter((d) => d.id == dish.id).reduce((n, { quantity }) => n + quantity, 0)}>
                  <Card
                    name='dish_card'
                    hoverable
                    style={isMobile ? { maxWidth: 400, height: 'auto', margin: '0 auto 0 auto', borderRadius: 15, overflow: "hidden" } : 
                    { width: '100%', height: '14rem', borderRadius: 15, overflow: "hidden" }}
                    title={
                      <div
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height:'7rem', fontSize:'1.1rem'}}
                      >
                        <div
                          className='card-title-desc'
                          style={{ maxWidth: '70%', display: 'flex', flexDirection: 'column', minWidth: 0, marginRight: 10 }}
                        >
                          <Title
                            style={{ minWidth: 0, wordBreak: 'break-word', whiteSpace: 'normal' }}
                            ellipsis={{ rows: 2 }}
                            level={4}>
                            {dish.name[currentLang] || dish.name[defaultLang]}
                          </Title>
                          <Paragraph
                            ellipsis={{ rows: 2 }}
                            style={{ minWidth: 0, wordBreak: 'break-word', whiteSpace: 'normal' }}
                          >
                            {dish.description[currentLang] || dish.description[defaultLang]}
                          </Paragraph>
                        </div>
                        {dish.image_url && (
                          <div onClick={(e) => e.stopPropagation()} className='card-image' style={{ width: 80, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                              <ZoomableImage
                                src={dish.image_url}
                                alt={dish.name[currentLang] || dish.name[defaultLang]}
                                width="100%"
                                height="auto"
                                style={{ objectFit: 'cover', maxHeight: '100%', maxWidth: '100%' }}
                              />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <Card.Meta
                      description={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxHeight:'426px' }}  >
                          <Text strong>${dish.price.toFixed(2)}</Text>
                          <PlusSquareOutlined
                            name='add_to_order_button'
                            style={{ fontSize: '24px', cursor: 'pointer' }}
                            onClick={() => {
                              if ((dish.extras != null && dish.extras.length > 0) || (dish.option_groups != null && dish.option_groups.length > 0) || (dish.description != null && dish.description[defaultLang] != '')) {
                                modifyModal(-1, true, { ...dish, selectedCondiments: {}, selectedExtras: [], category: categoryDish.category, quantity: 1 })
                              } else {
                                addToOrder({ ...dish, selectedCondiments: {}, selectedExtras: [], quantity: 1 }, categoryDish.category)
                              }
                            }}
                          />
                        </div>
                      }
                    />
                  </Card>
                </Badge>
              </List.Item>
            )}
          />
        </React.Fragment>
      ))}
    </>
  );
};



const CartItem = ({ name, price, quantity, selectedCondiments, selectedExtras, currentLang, defaultLang, removeFromOrder, addToOrder, modifyable, modifyModal, editLabel }) => {
  const condiments = selectedCondiments ? Object.entries(selectedCondiments).filter(([key, value]) => value.selected.length > 0).map(([key, value]) => (
    <div key={key}>
      <Text strong>{value.group_name[currentLang] || value.group_name[defaultLang]}: </Text>
      <Text>{value.selected.map(sel_name => sel_name[currentLang] || sel_name[defaultLang]).join(', ')}</Text>
    </div>
  )) : null;

  const extras = selectedExtras.length > 0 ? (
    <div>
      <Text strong>Extras: </Text>
      <Text>{selectedExtras.map(extra => extra.name[currentLang] || extra.name[defaultLang]).join(', ')}</Text>
    </div>
  ) : null;

  const extraPrice = selectedExtras.reduce((total, extra) => total + extra.price, 0);

  return (
    <div name='cart_item' className='cart-item'>
      <div className='cart-item-title' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text name='cart_item_name' strong style={{ color: 'black', fontSize: '16px' }}>{name[currentLang] || name[defaultLang]}</Text>
        <Text name='cart_item_price' type='secondary' >${(quantity * (price + extraPrice)).toFixed(2)}</Text>
      </div>
      <div className='cart-item-body' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='cart-item-details'>
          {condiments}
          {extras}
          {modifyable ? (
            <Button type='link' name='edit_button' style={{ marginLeft: 8 }} onClick={modifyModal}>
              {editLabel}
            </Button>
          ) : (
            <Button disabled type='link' name='edit_button' style={{ marginLeft: 8 }} onClick={modifyModal}>
              {editLabel}
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '25%' }}>
          <div className='cart-item-quantity' style={{ border: '1px solid', borderRadius: 4, padding: '0px 8%', fontSize: '16px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <MinusOutlined name='minus_button' style={{ cursor: 'pointer' }} onClick={removeFromOrder} />
            {quantity}
            <PlusOutlined name='plus_button' style={{ cursor: 'pointer' }} onClick={addToOrder} />
          </div>
        </div>
      </div>
      <Divider />
    </div>
  )
}



class FoodOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      dishes: [],
      filteredDishes: [],
      optional_states: [],
      deliver_places: [],
      emptyMenu: false,
      modifyModalVisible: false,
      modifyModalIndex: null,
      confirmOrderModalVisible: false,
      orderChangedModalVisible: false,
      isMobile: window.innerWidth <= 672,
      showCart: window.innerWidth > 672,
      menuChangedAlertVisible: false,
      recentlyFetchedWaitingTime: false,
      selectedTags: [],
      tagsToShow: [],
    };
    this.isClosedInterval = null // For the repeating function to check if restaurant is still open
    this.translate = this.props.intl.formatMessage // Get translation function from props
    this.categoryRefs = {};
    this.defaultLang = this.props.reqData.building.locales.split(',')[0] // Goes to list of languages in local storage and takes the first one, which is the default language
  }

  componentDidMount() {
    this.props.methods.pageMessageReceivedCallback() //dont show warning of menu update if it changed when page was not loaded
    this.props.hidePageText()
    window.addEventListener('resize', this.updateDimensions); // so we can render component on resize
    this.getDishes(this.props.page.id, () => {
      this.isClosed()
      
      const building_timezone = this.props.reqData.building.timezone
      let now = moment(moment.tz(building_timezone).format('HH:mm:ss'), "HH:mm:ss")
      let nextMin = now.clone().add(1, 'minutes').startOf('minute')
      
      setTimeout(() => {
        this.isClosed()
        this.isClosedInterval = setInterval(this.isClosed, 60000) // check if restaurant is still open every minute on the minute
      }, nextMin.diff(now, 'seconds') * 1000 + 5000)
      
    })
  }

  isClosed = () => {
    const building_timezone = this.props.reqData.building.timezone
    const now = moment(moment.tz(building_timezone).format('HH:mm:ss'), "HH:mm:ss")
    let openTime = this.state.openTime
    let closeTime = this.state.closeTime
    let isClosed = true;
    let willCloseIn = -1;
    let allDay = true;
    
    let today = this.state.restaurant_schedule.today
    let yesterday = this.state.restaurant_schedule.yesterday

    console.log('now', now)
    console.log('today', today)
    console.log('yesterday', yesterday)
    
    if (today != null && today.length > 0) {
      today.forEach(timeBlock => {
        openTime  = moment(timeBlock.open_time, "HH:mm:ss") 
        closeTime = moment(timeBlock.close_time, "HH:mm:ss")

        console.log('openTime', openTime)
        console.log('closeTime', closeTime)

        if (now.isBefore(openTime)) {
          allDay = false
        }

        if (openTime < closeTime && now.isBetween(openTime, closeTime)) {
          isClosed = false;
          willCloseIn = closeTime.diff(now, 'minutes') + 1
          console.log('seconds', closeTime.diff(now, 'seconds', true))
        }
      })
    }

    if (isClosed && yesterday != null && yesterday.length > 0) {
      yesterday.forEach(timeBlock => {
        openTime  = moment(timeBlock.open_time, "HH:mm:ss") 
        closeTime = moment(timeBlock.close_time, "HH:mm:ss")

        console.log('openTime', openTime)
        console.log('closeTime', closeTime)

        // ie, is open from 23:00 to 02:00 
        if (openTime > closeTime && !now.isBetween(closeTime, openTime)) {
          isClosed = false;
          willCloseIn = closeTime.diff(now, 'minutes') + 1
          console.log('seconds', closeTime.diff(now, 'seconds'))
        }
      })
    }

    console.log('isClosed', isClosed, 'willCloseIn', willCloseIn)
    this.setState({isClosed: isClosed, willCloseIn: willCloseIn, allDay: allDay})
  }

  filterDishes = (newSelectedTags) => {
    const filteredDishes = this.state.dishes.map(category => ({
      ...category,
      category_plates: category.category_plates.filter(dish =>{
        if (!dish.tags) {
          return false
        }else{
          return newSelectedTags.every(tag => dish.tags.includes(tag)) || false
        }
      } 
      )
    })).filter(category => category.category_plates.length > 0);
    this.setState({ filteredDishes })
  }

  componentDidUpdate() {
    // Reloads dishes when new mqtt message is received
    if (this.props.data && this.props.data.newMqttMessage && this.props.data.mqttTopicMessage === `menu_update`) {
      const mqttMessage = JSON.parse(this.props.data.mqttMessage)
      if (mqttMessage.restaurant_id == this.props.page.id && mqttMessage.type_of_save == 'publish') {
        this.handleMenuUpdate()
      }
    }
  }

  getDishes(page_id, callback = null) {
    SmartRequests.get('food_order/' + page_id).then(resp => {
      if (resp.status === 200 && resp.data.menu != null && resp.data.menu.length > 0) {
        resp.data.menu.forEach(category => {
          this.categoryRefs[category.category[this.defaultLang]] = React.createRef();// so we can scroll to category
        });
        const openTime = moment(resp.data.open_time, 'HH:mm')
        const closeTime = moment(resp.data.close_time, 'HH:mm')
        let tagsToShow = ['veggie', 'vegan', 'gluten_free'] // ids of the tags
        tagsToShow = tagsToShow.filter((tag) => {
          return resp.data.menu.some(dish => {
            return dish.category_plates.some(plate => {
              return plate.tags && plate.tags.includes(tag)
            })
          })
        })
        this.setState({
          dishes: resp.data.menu,
          menu_id: resp.data.menu_id,
          tagsToShow: tagsToShow,
          optional_states: resp.data.optional_states,
          restaurant_schedule : resp.data.restaurant_schedule,
          openTime: openTime,
          closeTime: closeTime,
          subTitleOpenHours: this.translate(msg.restaurantOpenHours,
                  {'openTime': openTime.format('HH:mm'),
                  'closeTime': closeTime.format('HH:mm')}),
          emptyMenu: false,
          deliver_places:resp.data.deliver_places
        }, 
        () => {
          if (callback != null) {
            callback();
          }
        });             
      } else {
        this.setState({ dishes: [], emptyMenu: true },
          () => {
            if (callback != null) {
              callback();
            }
          });
      }
    }).catch(e => this.setState({ dishes: [], emptyMenu: true }));

  }
  componentWillUnmount() {
    if (this.isClosedInterval) {
      clearInterval(this.isClosedInterval); // Make function to ckeck if restaurant is open stop repeating every minute
    }
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState(prevState => ({
      isMobile: window.innerWidth <= 672,
      showCart: (prevState.showCart || window.innerWidth >= 1600) && (window.innerWidth > 672 || prevState.isMobile)
    }));
  }
  addToOrder = (dish, category, position = null) => {
    let dishWithSelections
    if (dish.quantity != null) {
      dishWithSelections = {
        ...dish,
        category: category,
        quantity: dish.quantity
      };
    } else {
      dishWithSelections = {
        ...dish,
        category: category,
        quantity: 1
      };
    }
    this.setState((prevState) => {
      let order = prevState.order
      const dishInOrder = order.findIndex(d => d.id == dish.id && isEqual(d.selectedCondiments, dish.selectedCondiments) && isEqual(d.selectedExtras, dish.selectedExtras) && isEqual(d.category, category))
      if (dishInOrder != -1) { // dish is already in order, so just add to the quantity
        order[dishInOrder].quantity += dishWithSelections.quantity
      } else {
        if (position != null && position != -1) { // if needs to be inserted in a specific index
          order.splice(position, 0, dishWithSelections);
        } else { // just insert at the begginning
          order = [dishWithSelections, ...order]
        }
      }
      return { order: order, }
    });
  }

  removeFromOrder = (index, quantity = 1) => {
    this.setState(prevState => {
      let newOrder = prevState.order
      if (prevState.order[index].quantity > quantity) {
        newOrder[index].quantity = prevState.order[index].quantity - quantity
      } else {
        newOrder = prevState.order.filter((_, i) => i !== index)
      }
      return { order: newOrder }
    });
  }

  confirmOrderModal = () => {
    let waitingTime = this.state.waitingTime
    if (!this.state.recentlyFetchedWaitingTime) {
      // fetch waiting time
      SmartRequests.get('food_order/' + this.props.page.id + '/prep_time').then(resp => {
        if (resp.status === 200) {
          waitingTime = resp.data.prep_time
          this.setState({ recentlyFetchedWaitingTime: true, waitingTime: waitingTime });
          setTimeout(() => { // only fetch once every 5 minutes
            this.setState({ recentlyFetchedWaitingTime: false })
          }, 300000)
        }
      })
    }
    this.setState({ confirmOrderModalVisible: true, waitingTime: waitingTime });
  }

  handleCategoryClick = (category) => {
    if (this.categoryRefs[category] && this.categoryRefs[category].current) {
      this.categoryRefs[category].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }

  handleTagClick = (tag) => {
    //remove if is already in the array
    this.setState(prevState => {
      let newSelectedTags = prevState.selectedTags.slice()
      if (newSelectedTags.includes(tag)) {
        newSelectedTags = newSelectedTags.filter(t => t !== tag)
      } else {
        newSelectedTags.push(tag)
      }
      if(newSelectedTags.length != 0){
        this.filterDishes(newSelectedTags)
      }
      return {selectedTags: newSelectedTags}
    })
  }

  modifyModal = (index, firstTime, dishBeingModified) => {
    this.setState({ modifyModalVisible: true, modifyModalIndex: index, firstTimeOpeningModal: firstTime, dishBeingModified: dishBeingModified });
  }

  handleModifyOk = (selectedCondiments, selectedExtras, quantity) => {
    const prevDish = this.state.dishBeingModified
    const modifyModalIndex = this.state.modifyModalIndex
    // Sort all selections for comparison
    const defaultLang = this.defaultLang
    for (const [key, value] of Object.entries(selectedCondiments)) {
      value.selected.sort((a, b) => a[defaultLang] > b[defaultLang]) // Sort all selections for comparison
    }
    selectedExtras = selectedExtras.sort((a, b) => a.name[defaultLang] > b.name[defaultLang]) // Sort extras for comparison

    // If dish already in order, delete it
    if (modifyModalIndex != null && modifyModalIndex != -1) {
      this.removeFromOrder(this.state.modifyModalIndex, prevDish.quantity)
    }
    // Add new version of modified dish
    this.addToOrder({ ...prevDish, selectedCondiments: selectedCondiments, selectedExtras: selectedExtras, quantity: quantity }, prevDish.category, modifyModalIndex)

    this.setState({
      modifyModalVisible: false,
      modifyModalIndex: null,
      firstTimeOpeningModal: null
    })
  }

  handleModifyCancel = () => {
    this.setState({ modifyModalVisible: false, modifyModalIndex: null, dishBeingModified: null })
  }

  calculateTotalPrice = () => {
    return this.state.order.reduce((sum, dish) => {
      const extrasPrice = dish.selectedExtras.reduce((extrasSum, extra) => {
        return extrasSum + (extra ? extra.price : 0);
      }, 0);
      return sum + dish.quantity * (dish.price + extrasPrice);
    }, 0).toFixed(2);
  }

  confirmOrder = (receive_selected, notes, delivery_place = null) => {
    const order = this.state.order;
    let orderToSend = order.map((dish) => {
      let cleanSelectedCondiments = {};
      Object.keys(dish.selectedCondiments).forEach((key) => {
        if (dish.selectedCondiments[key].selected.length > 0) {
          cleanSelectedCondiments[key] = dish.selectedCondiments[key]
        }
      })
      return { name: dish.name, id: dish.id, price: dish.price, quantity: dish.quantity, selectedCondiments: cleanSelectedCondiments, selectedExtras: dish.selectedExtras, category: dish.category }
    })
    SmartRequests.post('food_order/' + this.props.page.id + '/order',
      {
        order: JSON.stringify(orderToSend),
        menu_id: this.state.menu_id, receive_method: receive_selected,
        notes: notes, delivery_place: delivery_place
      }
    ).then(resp => {
      this.setState({ confirmOrderModalVisible: false, order: [], showCart: !this.state.isMobile}); // Closes modal and cleans cart, hides cart if mobile so orders are visible 
      this.props.history.push({ pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/${this.props.page.name}/myorders` }) // Redirect to myOrders page
    })
  }

  // Handles reload of dishes when new mqtt message for menu update is received
  handleMenuUpdate = () => {
    // confirm message recieved to parent to turn newMqttMessage to false
    this.props.methods.pageMessageReceivedCallback()

    // get updated dishes
    this.getDishes(this.props.page.id, () => {

      // look for differences in dishes from order after menu update
      let dishesNotAvailable = []
      let dishesNameChanged = []
      let dishesDescriptionChanged = []
      let dishesPriceChanged = []
      let dishesOptionsChanged = []

      this.state.order.forEach((dishInOrder) => {
        const updatedDish = this.state.dishes.reduce((acc, dish) => {
          const dishFound = dish.category_plates.find(plate => plate.id === dishInOrder.id)
          return dishFound ? dishFound : acc
        }, null)
        // Need to let dishes know what to update/show
        if (updatedDish == null) {
          dishesNotAvailable.push(dishInOrder.id)
        } else {
          if (!isEqual(updatedDish.name, dishInOrder.name)) {
            dishesNameChanged.push(dishInOrder.id)
          }
          if (!isEqual(updatedDish.description, dishInOrder.description)) {
            dishesDescriptionChanged.push(dishInOrder.id)
          }
          if (!isEqual(updatedDish.price, dishInOrder.price)) {
            dishesPriceChanged.push(dishInOrder.id)
          }
          if (!isEqual(updatedDish.extras, dishInOrder.extras) || !isEqual(updatedDish.option_groups, dishInOrder.option_groups)) {
            dishesOptionsChanged.push(dishInOrder.id)
          }
        }
      })

      // If anything changed in the order, this is true
      const orderChanged = dishesNotAvailable.length > 0 || dishesNameChanged.length > 0 || dishesDescriptionChanged.length > 0 || dishesPriceChanged.length > 0 || dishesOptionsChanged.length > 0

      // Alert message for when menu is changed for current language
      let currentMenuChangedAlertDescription = this.translate(msg.menuChangedAlertDescription, { orderChanged: orderChanged })

      if (orderChanged) {
        // open order changed modal
        this.setState({ orderChangedModalVisible: true })
      } else {
        // show alert
        this.setState({ menuChangedAlertVisible: true, alertMessage: this.translate(msg.menuChangedAlertMessage), alertDescription: currentMenuChangedAlertDescription })
      }

    })
  }

  // Function that gets called from OrderChangedModal on accept
  handleAcceptOrderChanges = (newOrder) => {
    this.setState({ orderChangedModalVisible: false, order: newOrder })
  }
  render() {

    // TODO: get locale from local storage. First need to update local storage when changing language
    const currentLang = this.props.intl.locale // Gets selected language from props

    const totalPrice = this.calculateTotalPrice();

    var showMenu = []
    if(this.state.selectedTags.length !== 0){
      showMenu = this.state.filteredDishes
    }else{
      showMenu = this.state.dishes
    }
    return (
      <div>
        <Spin spinning={!this.state.emptyMenu && this.state.dishes.length === 0} delay={10}>
          <Layout style={{ minHeight: '100vh' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={3} style={{ margin: '16px 24px 16px 20px' }}>{this.props.page.title}</Title>
              <Button className='my-orders-button' type='primary' onClick={() => this.props.history.push({ pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/${this.props.page.name}/myorders` })}>
                {this.translate(msg.myOrdersButton)}
              </Button>
            </div>
            <MenuHeader 
              dishes={this.state.dishes}
              defaultLang={this.defaultLang}
              currentLang={currentLang}
              handleTagClick={this.handleTagClick}
              selectedTags={this.state.selectedTags}
              subTitleOpenHours={this.state.subTitleOpenHours}
              handleCategoryClick={this.handleCategoryClick}
              tagsToShow={this.state.tagsToShow}
            />
            <Layout>
              <Layout>
                <Content
                  style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                    backgroundColor: '#f0f2f5'
                  }}
                >
                  {this.state.emptyMenu ? (
                    <>
                      <br />
                      <Title level={1} disabled>
                        {this.translate(msg.emptyMenu)}
                      </Title>
                    </>
                  ) : (
                  <MenuComponent
                    dishes={showMenu}
                    categoryRefs={this.categoryRefs}
                    addToOrder={this.addToOrder}
                    modifyModal={this.modifyModal}
                    order={this.state.order}
                    isMobile={this.state.isMobile}
                    defaultLang={this.defaultLang}
                    currentLang={currentLang}
                  />
                  )}
                </Content>
              </Layout>

              {/* Sticky cart */}
              <Sider width={this.state.isMobile ? '100%' : 350} 
              collapsed={window.innerWidth < 1600 && !this.state.showCart} 
              collapsedWidth={this.state.isMobile ? 0 : 50} 
              style={this.state.isMobile ? 
              { backgroundColor: '#fff', position: 'fixed', top: 0, paddingTop: 'env(safe-area-inset-top)',bottom: 0, paddingBottom: 'env(safe-area-inset-bottom)',right: 0, zIndex: 1000, width: '100%'} : 
              { backgroundColor: '#fff', position: '-webkit-sticky', position: 'sticky', top: 65, height: '95vh' }}>
                {this.state.showCart && (
                  <>
                    {this.state.isMobile && (
                      <div className='page-header'>
                        <div className='top'>
                          <div name='hide_cart_button' className="back-link" onClick={() => this.setState({ showCart: false })}><Icon className="icon" type="arrow-left" /></div> 
                        </div>
                      </div>
                    )}
                    <div style={{ margin:  this.state.isMobile? '70px 20px 20px 20px' : '20px' }}>
                      {!this.state.isMobile && window.innerWidth < 1600 && (
                        <Button name='hide_cart_button' style={{ fontSize: '20px', marginLeft: '-15px', marginBottom: '15px' }} size="large" type='link' onClick={() => this.setState({ showCart: false })}><DoubleRightOutlined /></Button>
                      )}
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom: '2px dashed #e8e8e8', marginBottom: '1rem' }}>
                        <Title level={4} style={{ margin: 0 }}>{this.translate(msg.cartTitle)}</Title>
                        <div>
                          <Badge count={this.state.order.reduce((n, { quantity }) => n + quantity, 0)}>
                            <ShoppingCartOutlined style={{ fontSize: '24px' }} />
                          </Badge>
                        </div>
                      </div>
                      {/* List of products in cart */}
                      <List
                        dataSource={this.state.order}
                        style={this.state.isMobile ? { overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh', overscrollBehavior: 'contain', paddingBottom: '85px' } : { overflowY: 'auto', overflowX: 'hidden', maxHeight: '75vh', overscrollBehavior: 'auto' }}
                        renderItem={(dish, index) => {
                          const modifyable = (dish.extras != null && dish.extras.length > 0) || (dish.option_groups != null && dish.option_groups.length > 0)
                          return (
                            <CartItem
                              name={dish.name}
                              price={dish.price}
                              quantity={dish.quantity}
                              selectedCondiments={dish.selectedCondiments}
                              selectedExtras={dish.selectedExtras}
                              currentLang={currentLang}
                              defaultLang={this.defaultLang}
                              removeFromOrder={() => this.removeFromOrder(index)}
                              addToOrder={() => this.addToOrder({ ...dish, quantity: 1 }, dish.category)}
                              modifyModal={() => this.modifyModal(index, false, dish)}
                              modifyable={modifyable}
                              editLabel={this.translate(msg.edit)}
                            />
                          )
                        }}
                      />
                      {/* Cart footer */}
                      </div>
                      {
                        !this.state.isMobile && this.state.order.length > 0 && (
                          <Footer style={{ backgroundColor: '#fff', position: '-webkit-sticky', position: 'sticky',   padding: '20px',  bottom: 0, zIndex: 1000, display: 'flex', justifyContent: 'space-between', alignItems: 'center'  }}>
                            <Text strong>{this.translate(msg.total, { total: totalPrice })}</Text>
                            <Button
                              name='confirm_order_button'
                            type={this.state.order.length === 0 ? "default" : "primary"}
                              disabled={this.state.order.length === 0 || this.state.isClosed}
                              onClick={this.confirmOrderModal}
                            >
                              {this.translate(msg.confirm)}
                            </Button>
                          </Footer> 
                        )
                      }
                    </>
                )}
                {!this.state.showCart && (
                  <Button name='show_cart_button' size="large" type='link' style={{ marginTop: '15px', fontSize: '20px' }} onClick={() => this.setState({ showCart: true })}><DoubleLeftOutlined /></Button>
                )}
              </Sider>
            </Layout>
            {this.state.isMobile && this.state.order.length > 0 && (
              <Footer style={{ backgroundColor: '#fff', borderTop: '1px solid grey', position: '-webkit-sticky',
              padding: '19px', position: 'fixed', bottom: 0, zIndex: 1000, width:'100%' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text strong style={{ fontSize: '1.8rem' }}>{this.translate(msg.total, { total: totalPrice })}</Text>
                  {this.state.showCart ?
                    (<Button
                      name='confirm_order_button'
                      type={this.state.order.length === 0 ? "default" : "primary"}
                      key='confirm'
                      disabled={this.state.order.length === 0 || this.state.isClosed}
                      onClick={this.confirmOrderModal}
                    >
                      {this.translate(msg.confirm)}
                    </Button>)
                    :
                    (
                    <div>
                      <Badge
                        count={this.state.order.reduce((n, { quantity }) => n + quantity, 0)}
                        overflowCount={99} // Optional: limits the display count
                      >
                        <Button
                          name='show_cart_button'
                          key="open_cart"
                          type="primary"
                          onClick={() => this.setState({ showCart: true })}
                          style={{ position: 'relative' }}
                        >
                          <ShoppingCartOutlined style={{ fontSize: '18px' }} />
                          {this.translate(msg.showCart)}
                        </Button>
                      </Badge>
                    </div>
                    )}
                </div>
              </Footer>
            )}
            {this.state.modifyModalVisible && this.state.modifyModalIndex !== null && (
              <ModifyModal
                visible={this.state.modifyModalVisible}
                firstTime={this.state.firstTimeOpeningModal}
                cancelAddToOrder={this.handleModifyCancel}
                onCancel={() => this.setState({ modifyModalVisible: false, modifyModalIndex: null, dishBeingModified: null })}
                onOk={this.handleModifyOk}
                dish={this.state.dishBeingModified}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
              />
            )}
            {this.state.confirmOrderModalVisible && !this.state.orderChangedModalVisible && (
              <ConfirmOrderModal
                level={this.props.level}
                unit={this.props.unit}
                visible={this.state.confirmOrderModalVisible}
                onCancel={() => this.setState({ confirmOrderModalVisible: false })}
                onOk={(receive_selected, notes, delivery_place) => this.confirmOrder(receive_selected, notes, delivery_place)}
                order={this.state.order}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
                totalPrice={totalPrice}
                openTime={this.state.openTime}
                closeTime={this.state.closeTime}
                subTitleOpenHours={this.state.subTitleOpenHours}
                building_id={this.props.page.building_id}
                optional_states={this.state.optional_states}
                deliver_places={this.state.deliver_places}
                restaurant_title={this.props.restaurant_title}
                waitingTime={this.state.waitingTime}
                reqData={this.props.reqData}
                isMobile={this.state.isMobile}
              />
            )}
            {this.state.orderChangedModalVisible && !this.state.modifyModalVisible && (
              <OrderChangedModal
                level={this.props.level}
                visible={this.state.orderChangedModalVisible}
                order={this.state.order}
                dishes={this.state.dishes}
                removeDishes={(dishesToRemove) => this.removeDishes(dishesToRemove)}
                currentLang={currentLang}
                defaultLang={this.defaultLang}
                onOk={(newOrder) => this.handleAcceptOrderChanges(newOrder)}
                modifyModal={this.modifyModal}
                removeFromOrder={this.removeFromOrder}
              />
            )}
              <div className='alert-container'>
              {/* Place alerts in order of priority, top to bottom. */}
                {(this.state.isClosed) && (
                // Is closed
                <Alert
                  message={this.translate(msg.restaurantClosedTitle, {restaurant_name: this.props.restaurant_title, all_day: this.state.allDay})}
                  description={this.translate(msg.restaurantClosedDescription)}
                  type="error"
                  showIcon
                  className='alert'
                />
                )}
                {(this.state.willCloseIn >= 0 && this.state.willCloseIn <= 15) && (
                // Will close in less than 15 minutes
                  <Alert
                    message={this.translate(msg.closesSoonTitle, {restaurant_name: this.props.restaurant_title})}
                    description={this.translate(msg.closesSoonDescription, {minutes: this.state.willCloseIn})}
                    type="warning"
                    showIcon
                    className='alert'
                  />
                )}
                {(this.state.menuChangedAlertVisible) && (
                // Menu changed Alert
                  <Alert
                    message={this.state.alertMessage}
                    description={this.state.alertDescription}
                    type="warning"
                    showIcon
                    closable
                    className='alert'
                    onClose={() => this.setState({ menuChangedAlertVisible: false })}
                  />
                )}
              </div>
          </Layout>
        </Spin>
        <TransitionSwitch level={this.props.level + 1}>
          <Route
            path={`/:building/:unit/dashboard/:restaurantName/myOrders`}
            render={() => {
              return (<MyOrders
                loading={this.props.loading}
                level={this.props.level}
                name={'myorders'}
                page={this.props.page}
                subPages={this.props.subPages}
                unit={this.props.unit}
                reqData={this.props.reqData}
                parentPath={this.props.parentPath}
                data={this.props.data}
                methods={this.props.methods}
                initialData={this.props.initialData}
                url={this.props.url}
                defaultLang={this.defaultLang}
                currentLang={currentLang}
              />)
            }} />
        </TransitionSwitch>
      </div>
    );
  }
}

export default injectIntl(withRouter(FoodOrder));