import Axios from 'axios'
import { axios } from './authentication'
import BuildingInfo from '../../containers/Main/buildingInfo'
import { URLEncode } from '../utils'

export const NEW_STATE = 'NEW',
             RUNNING_STATE = 'RUNNING',
             CANCELLED_STATE = 'CANCELLED',
             FAILED_STATE = 'FAILED',
             AUTHENTICATION_ERROR_STATE = 'AUTH_ERROR'

const dataConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

const WS_VERSION = 2
const METHODS = {
  GET: async function (url, cancelToken) {
    const fullUrl = await getFullUrl(url)
    return axios.get(fullUrl, {cancelToken})
  },
  POST: async function (url, data, cancelToken) {
    const fullUrl = await getFullUrl(url)
    
    return axios.post(fullUrl, URLEncode(data), {cancelToken, ...dataConfig})
  },
  DELETE: async function (url, data, cancelToken) {
    const fullUrl = await getFullUrl(url)
    return axios.delete(fullUrl, URLEncode(data), {cancelToken, ...dataConfig})
  },
  PUT: async function (url, data, cancelToken) {
    const fullUrl = await getFullUrl(url)
    return axios.put(fullUrl, URLEncode(data), {cancelToken, ...dataConfig})
  },
  HEAD: async function (url, cancelToken) {
    const fullUrl = await getFullUrl(url)
    return axios.head(fullUrl, {cancelToken})
  }
}

export async function getFullUrl (url) {
  if (typeof url === 'string'){
    let isRelative = true;
    if (url.length>0) {
      const firstCharacter = url[0]
      isRelative = !['/', '.'].includes(firstCharacter)
    }
    if (isRelative) {
      const unit = await BuildingInfo.getCurrentUnit()
      const building = await BuildingInfo.getCurrentBuildingName()
      const fullUrl = `/${building}/resident/${unit}/ws/${WS_VERSION}/${url}`
      return fullUrl
    }
    return url
  }
  throw new Error(`Url ${JSON.stringify(url)} is not a string`)
}

export async function getUrl () {
    return getFullUrl("")
}

export class RequestQueueItem {
  constructor({method, unit, url, data, resolve, reject, final, params}) {
    this.method = method
    this.unit = unit
    this.url = url
    this.data = data
    this.resolve = resolve
    this.reject = reject
    this.final = final
    this.params = params || { }
    this.state = NEW_STATE
  }

  run = async () => {
    if (!this._isRunning()) {
      const cancelTokenSource = Axios.CancelToken.source();
      this._cancelRequest = cancelTokenSource.cancel;
     // const args =  [this.url];
     // if (this.data) args.push(this.data);
      const request = METHODS[this.method](this.url, this.data, cancelTokenSource.token);
      this.state = RUNNING_STATE;
      this._request = request.catch((error) => {
        if (Axios.isCancel(error)) {
          this.state = CANCELLED_STATE;
        } else if (error.request.status != 401) {
          this.state = FAILED_STATE;
          this.reject(error)
          return;
        } else if (!this.params.ignoreAuthError) {
          this.state = AUTHENTICATION_ERROR_STATE
        }
        throw error
      }).then(r => {this.resolve(r);})
        .finally(this.final)
    }
    return this._request
  }

  cancel = () => {
    if (typeof this._cancelRequest === 'function') {
      this._cancelRequest();
      delete this._cancelRequest;
      return true;
    }
    return false;
  }

  _isRunning = () => {
    return this.state === RUNNING_STATE
  }
}
