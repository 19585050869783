import React from 'react';
import Markdown from 'react-remarkable-with-plugins';
import Youtube from 'remarkable-youtube';
import remarkableMqtt from './remarkable-mqtt';
import remarkablePDF from './remarkable-pdf';

import Sanitize from 'sanitize-html';
import { ZoomableImage } from '../../components/ZoomableImage';

// FIXME
/* Backend renders markdown titles with no space after hashes, MDRenderer doesn't.
 * This markdown won't be rendered as tittle without this fix (which only adds that missing space):
 * 
 *   ##A markdown title :^)
 * 
 * Remove it when both backend and frontend uses same renderer.
 * 
 */
const FIX_REGEX = /^([#]{1,6})([^#\s].*)/;
const EOL = '\n'
function titlesFix(markdown = '') {
    const lines = markdown.split(EOL);
    const fixedLines = lines.map(line => line.replace(FIX_REGEX, '$1 $2'));
    const fixedMarkdown = fixedLines.join(EOL);
    return fixedMarkdown;
}

class MDRenderer extends React.Component {
    disallowedTags = [
        'script',
        'form',
        'iframe',
        'style',
        
    ]

    disallowedAttributes = [
        'onclick'
    ]

    componentDidMount = () => {
        this.sanitizeOptions = {
            allowedTags: false, // all
            allowedSchemes: [ 'http', 'https', 'mailto', 'tel' ],
            allowedAttributes: false, // all
            nonTextTags: this.disallowedTags, // it makes disallowed tags' text to do not be displayed
            exclusiveFilter: this.filterUnwanted
        }
    }

    filterUnwanted = (frame) => {
        const attrReducer = (isValid, attrib) => isValid && !this.disallowedAttributes.includes(attrib.toLowerCase());
        const frameAttributes = Object.keys(frame.attribs);
        const isValidTag = !this.disallowedTags.includes(frame.tag);
        const isValidAttribs = frameAttributes.reduce(attrReducer, true);
        return !(isValidTag && isValidAttribs);
    }

    getSanitizedChildren = () => {
        const cleanChildren = this.getMarkdownData().map(
            (child) => typeof child === 'string' ? this.sanitizeHtml(titlesFix(child)) : child
        )
        return cleanChildren;
    }

    getMarkdownData = () => {
        return this.props.children ?
            this._getChildrenWithParsedImages(this.props.children) :
            this.props.markdown ? [this.props.markdown] : ['']
    }

    _getChildrenWithParsedImages = () => {
        let regex
        let parsed = this.props.children
        // No way to set an attribute in markdown on images
        //
        // regex = /^\s*!\[(.*)\]\((.*)\)\s*$/g
        // parsed = this._replaceMarkdownMatchingRegex(parsed, regex, match => {
        //     const matchGroups = match.splice(1)
        //     const altText = matchGroups[0]
        //     const imgSrc = matchGroups[1]
        //     return <ZoomableImage src={imgSrc} alt={altText} />
        // })
        
        regex = /<img(?:\s+(.*))\s*\/>/g
        parsed = this._replaceMarkdownMatchingRegex(parsed, regex, match => {
            const matchingGroups = match.splice(1)
            const allAttributes = matchingGroups[0].trim().replace(/\s+/, ' ').split(' ').map(attr => {
                if (attr.includes('=')) {
                    const splittedAttr = attr.split('=')
                    const key = splittedAttr[0]
                    const value = splittedAttr[1].replace('"', '')
                    return [ key, value ]
                } else {
                    return [ attr, true ]
                }
            })
            const altTextAttr = allAttributes.find(attr => attr[0] === 'alt') || []
            const imgSrcAttr = allAttributes.find(attr => attr[0] === 'src')
            const isZoomable = allAttributes.find(attr => attr[0] === 'zoomable')
            if (imgSrcAttr && isZoomable) {
                return <ZoomableImage src={imgSrcAttr[1]} alt={altTextAttr[1]} />
            }
            return null
        })
        return parsed
    }

    _replaceMarkdownMatchingRegex = (children, regex, replacer) => {
        const newChildren = []
        children.forEach(child => {
            if (typeof child === 'string') {
                let matches
                let match
                let index
                let groups
                let hasMatched = false
                while (matches = regex.exec(child)) {
                    index = matches.index
                    groups = matches.splice(1)
                    match = matches[0]
                    let matchingString = match
                    let subStr1 = child.slice(0, index)
                    let subStr2 = child.slice(index + matchingString.length)
                    let replaced = replacer([ match, ...groups ]) 
                    if (replaced) {
                        hasMatched = true
                        newChildren.push(subStr1)
                        newChildren.push(replaced)
                        newChildren.push(subStr2)
                    }
                }
                if (!hasMatched) {
                    newChildren.push(child)
                }
            } else {
                newChildren.push(child)
            }
        })
        return newChildren
    }

    sanitizeHtml = (child) => {
        const sanitized = Sanitize(child, this.sanitizeOptions);
        return sanitized;
    }

    getExtraStyles = () => {
        return typeof this.props.className === 'string' ?
            this.props.className :
            '';
    }

    render = () => (
        <div className={`md-renderer ${this.getExtraStyles()}`}>
            <Markdown plugins={[Youtube, remarkableMqtt,remarkablePDF]} options={{html: true}}> 
                {this.getSanitizedChildren()} 
            </Markdown>
        </div>
    )
}

export default MDRenderer;
